/* Media Queries Screen Sizes   */
$tablet-size-breakpoint: 768px;
$mobile-size-breakpoint: 550px;

$primary-color-darkest: #0b3e79;
$primary-color-dark: #1565c0;
$primary-color-medium: #1e88e5;
$primary-color-light: #e3f2fd;

$primary-text-color: rgba(255, 255, 255, 0.75); // white;

$rounding-radius-default: 0.35rem;
