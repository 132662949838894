@import './src/assets/styles/sharedProperties.scss';
@import './src/assets/styles/variables.scss';

.cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 2em;
  margin-right: 2em;

  @extend %shadowing-card-effect-bottom;
  @extend %rounding-border;

  &:hover {
    /* On mouse-over, add a deeper shadow */
    @extend %shadowing-card-effect-bottom-hover;
    cursor: pointer;
    background-color: #f3f0f0ea;
  }

  &:active {
    @extend %shadowing-card-effect-bottom-active;
    background-color: lightgrey;
  }
}

.titleContainer {
  width: 100%;

  @extend %card_styling_title;
}

.cardPortraitParent {
  div img {
    border-bottom-left-radius: $rounding-radius-default;
    width: auto;
    height: 16em;
  }
}

.detailsParentContainer {
  flex-grow: 2;
  height: 16em;

  // Needed if legislator details container contains long text
  flex-basis: 40%;
}

.contactInfoParentContainer {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

@media (max-width: $tablet-size-breakpoint) {
  .cardContainer {
    flex-direction: row;
  }

  .cardPortraitParent {
    div img {
      border-bottom-left-radius: 0rem;
    }
  }

  .contactInfoParentContainer {
    width: 100%;
    border-left: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}

@media (max-width: $mobile-size-breakpoint) {
  .cardContainer {
    flex-direction: column;
  }

  .cardPortraitParent {
    align-self: center;

    div img {
      margin-top: 0.5em;
      max-width: 15em;
      border-radius: 0.25em;
    }
  }
}
