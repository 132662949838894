@import './src/assets/styles/variables.scss';

.detailsContainer{
    height: inherit;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: scroll;
}

@media(max-width: $tablet-size-breakpoint) {

}