@import './variables.scss';

/* This CSS will print because %message-shared is extended. */
%rounding-border {
  border-radius: $rounding-radius-default !important; // 0.25rem !important;
  border-top-left-radius: $rounding-radius-default;
  border-top-right-radius: $rounding-radius-default;
  border-bottom-right-radius: $rounding-radius-default;
  border-bottom-left-radius: $rounding-radius-default;
}

%shadowing-card-effect-bottom {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

%shadowing-card-effect-bottom-hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

%shadowing-card-effect-bottom-active {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

%card_styling_title {
  background-color: $primary-color-medium;
  color: white;
  border-bottom: 1px solid $primary-color-dark;

  //TODO RM: MAke title smaller here
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1;

  padding: 0.5em 1em;

  border-top-left-radius: $rounding-radius-default;
  border-top-right-radius: $rounding-radius-default;
}

%card_styling_icon {
  border-bottom-left-radius: $rounding-radius-default;
  width: auto;
  height: 16em;
}

%card_styling_detailsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 16em;
  overflow-x: scroll;
}

%card_styling_contactInfoParentContainer {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

%card_styling_contactInfoParentContainer_tablet {
  width: 100%;
  border-left: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

%card_styling_icon_mobile {
  flex-grow: 2;
  width: auto;
  height: auto;
}

%searchBar-divContainer {
  margin-left: 2em;
  margin-right: 2em;
  height: 3em;
  margin-bottom: 1em;
  margin-top: 1em;
}
