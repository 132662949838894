@import './src/assets/styles/sharedProperties.scss';
@import './src/assets/styles/variables.scss';

%button-with-svg-styling {
  background-color: transparent;
  border: none;
  fill: $primary-color-dark;

  &:hover {
    background-color: $primary-color-dark;
    fill: #fff;
  }

  &:active {
    fill: $primary-color-light;
  }
}

.searchBarContainer {
  height: inherit;
  display: flex;
  border-radius: 1.2rem !important;
  background: #fff;
  //   border: 1px solid #dfe1e5;

  @extend %shadowing-card-effect-bottom;

  &:hover {
    @extend %shadowing-card-effect-bottom-hover;
  }

  overflow: hidden;
}

.searchInput {
  background-color: transparent;
  border: none;
  flex-grow: 2;
  padding-left: 0.2em;
}

.searchIconBtn {
  @extend %button-with-svg-styling;
}

.cancelIconIconBtn {
  @extend %button-with-svg-styling;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
  background-color: lightgray;
}
