@import './src/assets/styles/variables.scss';

.title {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  color: $primary-color-darkest; // #5f6368;
  font-size: 7vmin;
  font-weight: 400;
}
