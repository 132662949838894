@import './src/assets/styles/variables.scss';

.layoutContainer {
  height: inherit;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: $primary-color-darkest;
}

.mainContent {
  flex-grow: 2;
}
