.contentContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentText {
  text-align: center;
  padding: 2em;
  font-size: 1.5em;
  font-weight: 300;
}
