@import './src/assets/styles/sharedProperties.scss';
@import './src/assets/styles/variables.scss';

.contactCardContainer {
    padding: .5em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    display: flex;
    flex-direction: column;

    height: 100%;

    h6 {
        text-align: center;
        font-weight: bold;
    }
}

.contactDetailsContainer {
    display: flex;
    flex-direction: inherit;
    flex-grow: 2;
}

.contactItem {
    flex-grow: 1;

    &:hover {
        background-color: aliceblue;
    }

    img {
        width: 1.5em;
        height: auto; 
    }

    span {
        padding-left: .3em;
    }
}

@media(max-width: $tablet-size-breakpoint) {
    .contactDetailsContainer {
        flex-direction: inherit;
    }

    .contactCardContainer {
        flex-direction: row;
        align-items: baseline;
        
        h6 {
            font-weight: 400;
        }
    }

    .contactItem {
        text-align: center;

        img {
            padding-left: 0em;
        }
    
        span {
            font-size: .7em;
        }
    }
}

@media(max-width: $mobile-size-breakpoint) {
    .hideLabelOnMobile {
        span {
            display: none;
        }
    }
}
