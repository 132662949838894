.textContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // TODO RM: Add as a global variable and use here and in Title component
  color: #5f6368;
  font-size: 2em;
  font-weight: 300;
}
