@import './src/assets/styles/variables.scss';

.container {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 0.7em;
  flex-grow: 1;
  display: flex;
  font-size: 0.9em;
}

.stackForSmallScreen {
  div {
    padding-left: 0.5em;
  }
}

.alwaysStackItems {
  flex-direction: column;
}

.capitalizeText::first-letter {
  text-transform: capitalize;
}

@media (max-width: $tablet-size-breakpoint) {
  .stackForSmallScreen {
    flex-direction: column;

    div {
      padding-left: 0em;
    }
  }

  .container {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
  }
}
