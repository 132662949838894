@import './src/assets/styles/variables.scss';

$portrainAndInfo-heigth-fullscreen: 25em;

.parentContainer {
  & [class*='slick-prev'] {
    &:hover {
      // background-color: cornflowerblue;
    }
  }
}

.title {
  width: 100%;
}

.portrait {
  border-radius: 0.25rem;
  height: $portrainAndInfo-heigth-fullscreen;
  width: auto;
}

.textDetailsContainer {
  flex-grow: 2;
  max-height: $portrainAndInfo-heigth-fullscreen;
  overflow-y: scroll;
}

.contactCard {
  height: $portrainAndInfo-heigth-fullscreen;
}

@media (max-width: 850px) {
  .contactCard {
    height: auto;
    width: 100%;
    flex-direction: row;
    text-align: center;
  }
}

@media (max-width: $tablet-size-breakpoint) {
}

@media (max-width: $mobile-size-breakpoint) {
}
