@import './src/assets/styles/variables.scss';

.header {
  background-color: $primary-color-darkest;
}

.headerText {
  font-weight: 500;

  span {
    background-color: $primary-color-medium;
    border-radius: $rounding-radius-default;
    margin-left: 0.5em;
    padding: 0.2em;
    border-color: $primary-color-light;
    border-width: 1px;
    border-style: solid;
    font-size: small;
  }
}

.headerLogo {
  height: 33px;
  width: 33px;
  margin-right: 5px;
}

.navBarItemContainer {
  flex-flow: row-reverse;
}
