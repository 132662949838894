@import './src/assets/styles/sharedProperties.scss';
@import './src/assets/styles/variables.scss';

.searchBarContainer {
  @extend %searchBar-divContainer;
  display: flex;

  [class*='searchBarContainer'] {
    flex-grow: 1;
  }
}

.modalContainer {
  background-color: white;
  border-radius: 1em;
  max-height: 90vh;
  // Left and right margins are needed so the carousel arrows show
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
