@import './src/assets/styles/sharedProperties.scss';

.pageContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.searchBarContainer{
    @extend %searchBar-divContainer;
}

.contentContainer {
    flex-grow: 2;
}