@import './src/assets/styles/sharedProperties.scss';
@import './src/assets/styles/variables.scss';

.cardContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;

  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 2em;
  margin-right: 2em;

  @extend %shadowing-card-effect-bottom;
  @extend %rounding-border;

  &:hover {
    /* On mouse-over, add a deeper shadow */
    @extend %shadowing-card-effect-bottom-hover;
  }
}

.titleContainer {
  width: 100%;

  @extend %card_styling_title;
}

.detailsParentContainer {
  flex-grow: 2;
  flex-basis: 70%;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 22em;
  overflow-x: scroll;
}

.contactInfoParentContainer {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

@media (max-width: $tablet-size-breakpoint) {
  .cardContainer {
    flex-direction: row;
  }

  .detailsContainer {
    strong {
      display: block;
    }
  }

  .contactInfoParentContainer {
    width: 100%;
    border-left: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}

@media (max-width: $mobile-size-breakpoint) {
}
